/* App.css */
.app {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 58px);
}

html, body {
  margin: 0;
  padding: 0;
  /* height: 100%;
  margin: 0; */
  /* display: flex;
  flex-direction: column;
  width: 100%;
  */
  min-height: 100vh;
}

.content {
  flex: 1;
  padding: 20px;
}

/* Navbar.css */
.navbar {
  background-color: #333;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Footer.css */
.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px; 
  position: relative;
  bottom: 0;
  width: 100%;
}

#map {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

}
